import React from 'react'
import { useLocation, useHistory } from '@kaliber/routing'
import { parseQueryString, stringifyQueryString } from './machinery/querystring'

/**
 * @returns {[{[key:string]: any}, (any) => void]}
 */
export function useQueryString() {
  const location = useLocation()
  const history = useHistory()
  const [queryString, setQueryString] = React.useState(parseQueryString(location.search))

  React.useEffect(
    () => {
      const unscubscribe = history.listen(({ location }) => setQueryString(parseQueryString(location.search)))
      return unscubscribe
    },
    [history]
  )

  return [
    queryString,
    React.useCallback(
      (fncOrState, { state = undefined, replace = true } = {}) => {
        const currentQS = parseQueryString(history.location.search)
        const newState = typeof fncOrState === 'function' ? fncOrState(currentQS) : fncOrState

        const filteredState = Object.fromEntries(
          Object.entries(newState)
            .filter(([k, v]) => !Array.isArray(v) || v.length)
        )

        const newQS = stringifyQueryString(filteredState)
        history.navigate(Boolean(newQS) 
          ? `${window.location.pathname}?${newQS}${window.location.hash}` 
          : `${window.location.pathname}${window.location.hash}`, { state, replace })
      },
      [history]
    )
  ]
}
