import qs from 'query-string'

const arrayFormat = 'bracket-separator'
const arrayFormatSeparator = ','

const queryStringOptions = {
    /** @type {import('query-string').ParseOptions} */
    parse: {
        arrayFormat,
        arrayFormatSeparator
    },
    /** @type {import('query-string').StringifyOptions} */
    stringify: {
        arrayFormat,
        arrayFormatSeparator,
        skipEmptyString: true,
        skipNull: true,
    }
}

export function parseQueryString(query, options = queryStringOptions.parse) {
    return qs.parse(query, options)
}

export function stringifyQueryString(query, options = queryStringOptions.stringify) {
    return qs.stringify(query, options)
}
